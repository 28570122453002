.moduleMainDiv {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  max-width: 100%;
  flex-grow: 1;
  background-color: #f9f9f9;
  padding: 15px;
}

.moduleHeadervDiv {
  display: flex;
  align-items: center;
  padding: 15px 25px;
  background: linear-gradient(to right, #7eb3e8, #3990e8);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-bottom: 20px;
}

.moduleNameDiv {
  margin-bottom: 20px;
}

.moduleName {
  cursor: pointer;
  font-size: 28px;
  color: #1976d2;
  transition: color 0.3s ease;
}

.moduleName:hover {
  color: #1976d2;
}

.moduleMngActionDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.moduleMngActionBtns {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  max-width: 1200px;
  margin-top: 10px;
  padding: 0 20px;
}

/* Style for all buttons in the navigation */
.moduleMngActionBtns button {
  min-width: 200px;
  height: 50px;
  padding: 20px;
  border-radius: 12px;
  font-weight: 500;
  transition: all 0.3s ease;
  align-items: center;
  justify-content: center;
  gap: 12px;
  background-color: #4c7caf;
  border: none;
  color: white;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  margin: 0 10px;
}

.moduleMngActionBtns button:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  background-color: #1976d2;
}

/* Active button style */
.active_module_nav {
  background: linear-gradient(45deg, #85b7ea, #02478c) !important;
  color: white !important;
}

.active_module_nav:hover {
  background: linear-gradient(45deg, #85b7ea, #02478c) !important;
}

/* Configuration menu button specific style */
.moduleMngActionBtns button:last-child {
  background-color: #85b7ea;
}

@media (max-width: 600px) {
  .moduleName {
    font-size: 20px;
  }

  .moduleMngActionBtns {
    flex-direction: column;
    align-items: center;
  }

  .moduleMngActionBtns .MuiButton-root {
    width: 100%;
    text-align: center;
  }

  .moduleMngActionDiv {
    padding: 10px;
  }
}

@media (max-width: 400px) {
  .moduleName {
    font-size: 18px;
  }

  .moduleMngActionBtns .MuiButton-root {
    font-size: 14px;
  }
}

/* .moduleMngActionBtns .MuiButton-root {
  margin: 5px;
} */

/* .moduleHeadervDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  background-color: #f4f6f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
} */

/* .moduleMainDiv {
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  max-width: 100%;
  flex-grow: 1;
  background-color: #f9f9f9;
  padding: 15px;
}

.moduleHeadervDiv {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 20px;
  background-color: #f4f6f9;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.moduleNameDiv {
  display: flex;
  width: 100%;
  flex-grow: 1;
  padding: 10px;
}

.moduleName {
  font-size: 28px;
  font-weight: bold;
  color: #007bff;
  text-align: left;
  cursor: pointer; 
}

.moduleMngActionDiv {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  padding: 10px;
  margin-bottom: 10px;
}

.moduleCreateBtn {
  background-color: #007bff;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.moduleSaveBtn {
  background-color: #ff7f00;
  color: #fff;

  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.moduleSaveBtn:hover {
  background-color: #28a745;
}

.moduleDisplay {
  box-sizing: border-box;
  max-width: 100%;
  height: auto;
  flex-grow: 1;
}

.active_module_nav {
  background-color: #1890ff;
  color: white;
} */
