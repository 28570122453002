.ModuleUpdateView {
  display: flex;
  max-width: 95%;
  flex-grow: 1;
  /* justify-content: center;
    align-items: center; */
  flex-direction: column;
  padding: 5px;
  /* background-color: #f8f9fa; */
  /* border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */

  /* max-width: 1000px; */
  /* margin: 40px auto; */
  /* font-family: Arial, sans-serif; */
}

.ModuleUpdateViewForm {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.ModuleUpdateViewForm_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #e0e0e0;
  padding-bottom: 10px;
}

.RecordID h1 {
  color: #007bff;
  font-size: 24px;
  font-weight: bold;
  margin: 0;
}

.ModuleUpdateViewForm_Bottom {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  font-size: 16px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.ModuleUpdateViewForm_Bottom:hover {
  background-color: #0056b3;
}

.ModuleUpdateViewForm_Body {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.FormViewPage {
  width: 100%;
  background: #ffffff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.CustomStepStyles {
}

/* .FormViewPage h3 {
  color: #333;
  font-size: 20px;
  margin-bottom: 15px;
  text-align: left;
} */

.FormPage {
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  width: fit-content;
  max-width: 100%;
  /* flex-wrap: nowrap; */
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}
