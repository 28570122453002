/* General ListView Styling */
.ListView {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* Header Styling */
.ListView_Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 8px;
}

.ListView_Header_Actions button {
  background-color: #1890ff;
  color: white;
  border: none;
  transition: background-color 0.3s ease;
}

.ListView_Header_Actions button:hover {
  background-color: #40a9ff;
}

/* Table Container */
.ListView_Table {
  margin-top: 16px;
}

.ListView_Table .MuiDataGrid-root {
  border: none;
  font-family: "Arial", sans-serif;
}

.ListView_Table .MuiDataGrid-cell {
  font-size: 14px;
}

.ListView_Table .MuiDataGrid-columnHeaders {
  background-color: #fafafa;
  font-weight: bold;
}

/* Empty State Styling */
.Empty {
  text-align: center;
  color: #999;
  margin-top: 16px;
}
