.exchange-rates-container {
  width: 500px;
  height: 50px; /* Adjust based on your navbar height */
  overflow: hidden; /* Prevent overflow */
  /* background-color: red;  */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for better visibility */
}

.exchange-rates-board {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%; /* Ensure it fills the container */
  font-size: 12px; /* Reduce font size for a compact display */
  padding: 5px;
}

.exchange-rates-list {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.exchange-rates-item {
  margin-bottom: 5px;
  display: flex;
  justify-content: space-between;
}

.exchange-rates-currency {
  font-weight: bold;
}

.exchange-rates-value {
  font-size: 10px;
  color: #fff;
}

.loading,
.error-message {
  text-align: center;
  font-size: 14px;
  color: #888;
}
