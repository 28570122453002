.BadgeDiv {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-around;
}

.ProductEditModalHeaderDiv {
  width: 100%;
  /* background-color: blue;
  color: white; */
}

.ProductEditModalHeaderDiv h3 {
  font-family: "Times New Roman", Times, serif;
  font-weight: bold;
  font-size: 24px;
  text-decoration: underline;
}

.ProductEditModalFooterDiv {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  /* background-color: antiquewhite; */
}

.ProductAuthDiv {
  display: flex;
  flex-direction: column;
}

.productlabels {
  display: flex;
  flex-direction: column;
}

.ProductPreviewImg {
  max-width: 100;
  height: 100px;
  border-radius: 10px;
}

/* new Product Form */
.NewProductForm {
  max-width: 100%;
  display: flex;
  flex-direction: column;
}

.NewProductModelDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.productPropertyCheckDiv {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/*editproduct  */
.editproductform {
  max-width: 100%;
}

.EditProductSaleModelDiv {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.EditProductSaleModelContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.StockLevel {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.StockColumn {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.productInternalReflabel {
  width: 200px;
  font-weight: bold;
}

.productInternalReflabel input {
  width: 200px;
  font-weight: bold;
}
