* {
  margin: 0;
  padding: 0;
}

.landing {
  display: flex;
  width: 100%;
  min-height: 100vh;
  flex-direction: column;
}

.AppBarDiv {
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000;
  background-color: aqua;
}

.landingDisplay {
  /* padding-top: 10%; Matches the height of AppBarDiv */
  margin-top: 0%;
  /* padding-top: 5%; */
  padding: 0; /* Ensure no padding */
  width: 100%;
  /* height: auto; */
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  /*overflow-y: auto; Enable scrolling if content overflows */
  background-color: whitesmoke;
}

.AppsContainer {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 24px;
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.AppCard {
  background: white;
  border-radius: 12px;
  padding: 20px;
  text-align: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}

.AppCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.AppIcon {
  width: 64px;
  height: 64px;
  margin-bottom: 12px;
  object-fit: contain;
}

.AppName {
  margin: 0;
  color: #333;
  font-size: 16px;
  font-weight: 500;
}

/* Make it responsive */
@media (max-width: 768px) {
  .AppsContainer {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 16px;
    padding: 16px;
  }

  .AppIcon {
    width: 48px;
    height: 48px;
  }

  .AppName {
    font-size: 14px;
  }
}
