.Discuss {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  display: flex;
  flex-direction: row;
}

.discuss_nav {
  width: 20%;
  height: 100%;
  background-color: red;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.discuss_display {
  width: 80%;
  height: 100%;
  background-color: yellowgreen;
}

.msg_btn{
  background-color: blue;
  color: white;
  border-radius: 8px;
  width: 100px;
}
