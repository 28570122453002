/* Task Board Container */
.task-board {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 1rem;
  padding: 1rem;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
}

/* Column Styling for Droppable Areas */
/* .column {
  min-width: 250px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 0.5rem;
} */

/* Column Header */
.task-Container-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.task-Container-head h2 {
  font-size: 18px;
  font-weight: 600;
  color: #2c3e50;
  margin: 0;
}

.task-Container-head button {
  background-color: #3498db;
  padding: 8px;
  border-radius: 50%;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.task-Container-head button:hover {
  background-color: #2980b9;
}

/* Droppable Area (Empty or with Tasks) */
.task-Container {
  height: 100%;
  min-height: 200px;
}

/* When No Tasks Are in a Column */
.task-Container p {
  color: #7f8c8d;
  text-align: center;
  font-size: 14px;
  margin-top: 50px;
}

/* Styling for Task Items */
.task-top {
  background-color: #ecf0f1;
  padding: 10px;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  color: #2c3e50;
  cursor: move;
  transition: background-color 0.3s ease;
}

.task-top:hover {
  background-color: #bdc3c7;
}

/* Task Body */
.task-body {
  background-color: #fafafa;
  padding: 10px;
  border-radius: 8px;
  font-size: 14px;
  color: #7f8c8d;
  /* display: none;  */
}

/* .task-top:hover + .task-body {
  display: block; 
} */

/* Styling for Drag and Drop Behavior */
/* .dragging {
  opacity: 0.5;
  transition: opacity 0.3s ease;
}

.dragging-over {
  background-color: #f39c12;
  box-shadow: 0 0 10px rgba(243, 156, 18, 0.5);
} */

/* Custom Modal Styles */
/* .custom-modal {
  width: 90% !important;
  max-width: 600px !important;
  padding: 20px;
  border-radius: 8px;
  background-color: #f9f9f9; 
} */

/* Input Field Styles */
.task-title-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

/* Textarea Styles */
.task-description-textarea {
  width: 100%;
  padding: 10px;
  height: 120px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: vertical; /* Allow vertical resize */
}

/* Focus Effect */
.task-title-input:focus,
.task-description-textarea:focus {
  border-color: #007bff;
  outline: none;
}

/* Modal Button Custom Styles (Optional) */
.ant-modal-footer {
  background-color: #f1f1f1;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}

/* Responsive Design */
@media (max-width: 768px) {
  .task-board {
    grid-template-columns: 1fr;  /* Stack columns on mobile */
    gap: 0.5rem;
    padding: 0.5rem;
  }

  /* .column {
    min-width: unset;
  } */

  .task-Container-head {
    padding: 0.5rem;
  }
}
