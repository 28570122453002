.no-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh; /* Full viewport height */
  text-align: center;
  background-color: #f8f9fa; /* Light background color */
  color: #343a40; /* Dark text color */
}

.no-page-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.no-page-message {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.no-page-link {
  padding: 10px 20px;
  background-color: #007bff; /* Primary color */
  color: white;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.no-page-link:hover {
  background-color: #0056b3; /* Darker shade on hover */
}
