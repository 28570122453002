.Dashboard {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardsContainer {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.cardsContainer .Card {
  width: 190px;
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.cardsContainer .Card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.cardsContainer .Card img {
  width: 80px;
  height: 80px;
  margin-bottom: 15px;
  transition: transform 0.3s ease;
}

.cardsContainer .Card:hover img {
  transform: scale(1.1);
}

.cardsContainer .Card .title {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  margin-bottom: 5px;
}

.cardsContainer .Card .count {
  font-size: 22px;
  font-weight: 600;
  color: #007bff;
}
