.settings {
  display: flex;
  min-height: calc(100vh - 60px); /* Adjust based on your header height */
  background-color: #f5f6fa;
}

.settingNav {
  width: 280px;
  background-color: #fff;
  box-shadow: 2px 0 4px rgba(0, 0, 0, 0.05);
  padding: 24px 0;
}

.settingNav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.settingNav li {
  margin: 4px 0;
}

.settingNav a {
  display: flex;
  align-items: center;
  padding: 12px 24px;
  color: #64748b;
  text-decoration: none;
  transition: all 0.2s ease;
  font-size: 15px;
}

.settingNav a:hover {
  background-color: #f1f5f9;
  color: #0f172a;
}

.settingNav a.active {
  background-color: #e2e8f0;
  color: #0f172a;
  font-weight: 500;
  border-right: 3px solid #2563eb;
}

.icon {
  width: 20px;
  height: 20px;
  margin-right: 12px;
  display: inline-block;
  opacity: 0.7;
}

.settingDisplay {
  flex: 1;
  padding: 32px;
  background-color: #fff;
  margin: 24px;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .settings {
    flex-direction: column;
  }
  
  .settingNav {
    width: 100%;
    padding: 12px 0;
  }
  
  .settingDisplay {
    margin: 12px;
    padding: 20px;
  }
}
