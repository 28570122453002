.inventoryDashboard {
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;
  font-family: Arial, sans-serif;
}

h1 {
  text-align: center;
  color: #333;
  margin-bottom: 30px;
}

.dashboardStats {
  display: grid;
  max-width: 100%;
  grid-template-columns: repeat(auto-fill, minmax(220px, 1fr));
  gap: 20px; /* Space between cards */
  margin-bottom: 30px;
  width: 100%;
  padding: 10px;
}

.statCard {
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.statCard:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.statCard h2 {
  font-size: 1.4rem;
  margin-bottom: 10px;
  color: #333;
}

.statCard p {
  font-size: 2.4rem;
  font-weight: bold;
  color: #007bff;
  margin: 0;
}

.activityTable {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.activityTable th,
.activityTable td {
  padding: 10px;
  border: 1px solid #ddd;
  text-align: left;
}

.activityTable th {
  background-color: #f4f4f4;
  font-weight: bold;
}

.activityTable tr:nth-child(even) {
  background-color: #f9f9f9;
}

.activityTable tr:hover {
  background-color: #f1f1f1;
}

.dashboard {
  min-height: 100vh;
  padding: 20px;
  font-family: 'Roboto', sans-serif;
  background: linear-gradient(135deg, #bbdefb 0%, #90caf9 50%, #64b5f6 100%);
}

.dashboard-title {
  text-align: center;
  font-size: 2em;
  font-weight: bold;
  color: #1976d2;
  margin-bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

.metrics-row {
  margin-bottom: 20px;
}

.metric-card {
  border-radius: 12px;
 
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: all 0.3s ease;
  width: 90%;
  margin: 10px auto;
  border-left: 5px solid #1976d2;
}

.metric-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.15);
  background-color: #ffffff;
}

.metric-card h3 {
  color: #1976d2;
  font-size: 1.2em;
  margin: 10px 0;
}

.metric-icon {
  font-size: 2.5em;
  color: #1976d2;
  margin-bottom: 10px;
}

.metric-value {
  font-size: 1.8em;
  font-weight: bold;
  color: #0d47a1;
  margin: 10px 0 0 0;
}

/* Specific colors for different metric cards */
.total-products {
  border-left-color: #1976d2;
}

.categories {
  border-left-color: #1e88e5;
}

.sub-categories {
  border-left-color: #2196f3;
}

.warehouses {
  border-left-color: #42a5f5;
}

/* Table styles */
.ant-table {
  background: rgba(255, 255, 255, 0.9);
  border-radius: 12px;
  overflow: hidden;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 20px;
}

.ant-table-thead > tr > th {
  background-color: #1976d2 !important;
  color: white !important;
  font-weight: 600;
  padding: 16px;
}

.ant-table-tbody > tr > td {
  padding: 16px;
  border-bottom: 1px solid #e3f2fd;
}

.ant-table-tbody > tr:hover > td {
  background-color: #e3f2fd !important;
}

h2 {
  color: #1976d2;
  margin: 30px 0 20px;
  font-weight: 600;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
  .dashboard {
    padding: 15px;
  }

  .dashboard-title {
    font-size: 1.8em;
  }

  .metric-card {
    padding: 20px;
  }

  .metric-icon {
    font-size: 2em;
  }

  .metric-value {
    font-size: 1.5em;
  }
}

@media (max-width: 768px) {
  .dashboardStats {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
    gap: 15px;
  }

  .statCard h2 {
    font-size: 1.2rem;
  }

  .statCard p {
    font-size: 2rem;
  }

  .activityTable th, .activityTable td {
    padding: 8px;
    font-size: 0.9rem;
  }
}
