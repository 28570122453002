


















/* Antd icons style */
.anticon {
  font-size: 24px;
}

/* Menu styling */
.MuiMenu-paper {
  border-radius: 8px !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15) !important;
  background-color: #ffffff !important;
}

.MuiMenuItem-root {
  padding: 12px 20px !important;
  transition: background-color 0.3s ease !important;
}

.MuiMenuItem-root:hover {
  background-color: #e8f5e9 !important;
}

/* Responsive design */
@media (max-width: 768px) {
  .moduleHeadervDiv {
    padding: 12px 15px;
  }

  .moduleName {
    font-size: 24px;
  }

  .moduleMngActionBtns {
    flex-direction: column;
    align-items: center;
    gap: 15px;
  }

  .moduleMngActionBtns button {
    width: 100%;
    max-width: 300px;
    height: 100px;
    margin: 0;
  }
}

@media (max-width: 480px) {
  .moduleName {
    font-size: 20px;
  }

  .moduleHeadervDiv {
    padding: 10px;
  }

  .moduleMngActionBtns button {
    height: 80px;
  }
}
