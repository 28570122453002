
.modal-container {
    width: 80%; 
    max-width: 600px; 
    margin: auto; 
    padding: 20px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  
  .modal-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .modal-form label {
    font-weight: bold;
    font-size: 16px;
  }
  
  .modal-form select,
  .modal-form input {
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 100%;
  }
  
  .modal-form button {
    background-color: #007bff;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .modal-form button:hover {
    background-color: #0056b3;
  }
  

  @media (max-width: 600px) {
    .modal-container {
      width: 30%; 
      padding: 15px; 
    }
  
    .modal-form label {
      font-size: 14px; 
    }
  
    .modal-form select,
    .modal-form input {
      font-size: 14px;
      padding: 10px; 
    }
  
    .modal-form button {
      font-size: 14px; 
      padding: 12px 20px; 
    }
  }
  