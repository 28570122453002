/* General layout */
.documentation-container {
  display: flex;
  height: 100vh;
  font-family: Arial, sans-serif;
}

/* Sidebar */
.sidebar {
  width: 250px;
  background-color: #f8f9fa;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
}

.sidebar h2 {
  font-size: 1.5rem;
  color: #343a40;
}

.sidebar ul {
  list-style: none;
  padding: 0;
}

.sidebar li {
  margin: 10px 0;
}

.sidebar a {
  color: #007bff;
  text-decoration: none;
  font-size: 1.1rem;
}

.sidebar a:hover {
  color: #0056b3;
}

/* Main Content */
.main-content {
  flex-grow: 1;
  padding: 20px;
  overflow-y: auto;
}

.main-content h1 {
  font-size: 2rem;
  color: #343a40;
}

.main-content p {
  font-size: 1.1rem;
  line-height: 1.6;
}
