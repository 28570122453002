.profile-page {
  padding: 2rem;
  background-color: #f8f9fa;
  min-height: 100vh;
}

.profile-container {
  max-width: 1200px;
  margin: 0 auto;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  padding: 2rem;
}

.profile-title {
  color: #2c3e50;
  font-size: 24px;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.profile-content {
  display: grid;
  grid-template-columns: 250px 1fr;
  gap: 2rem;
}

.profile-sidebar {
  text-align: center;
}

.profile-image-container {
  margin-bottom: 1.5rem;
}

.profile-avatar {
  width: 200px;
  height: 200px;
  border-radius: 50%;
  object-fit: cover;
  border: 4px solid #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.profile-details {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
}

.profile-field {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.profile-field label {
  color: #6c757d;
  font-size: 0.9rem;
  font-weight: 500;
}

.profile-field input {
  padding: 0.75rem;
  border: 1px solid #dee2e6;
  border-radius: 6px;
  font-size: 1rem;
  background-color: #f8f9fa;
  color: #495057;
  width: 100%;
}

.profile-field input:read-only {
  cursor: not-allowed;
}

.profile-actions {
  margin-top: 1rem;
}

.edit-profile-btn {
  background-color: #0d6efd;
  color: white;
  border: none;
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.edit-profile-btn:hover {
  background-color: #0b5ed7;
}

@media (max-width: 768px) {
  .profile-content {
    grid-template-columns: 1fr;
  }
  
  .profile-container {
    padding: 1rem;
  }
  
  .profile-avatar {
    width: 150px;
    height: 150px;
  }
}
