.ModuleUpdateView {
    display: flex;
    flex-direction: column;
    margin: 20px;
  }
  
  .ModuleUpdateViewForm {
    display: flex;
    flex-direction: column;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }
  
  .ModuleUpdateViewForm_Header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .RecordID h1 {
    font-size: 24px;
    font-weight: 600;
  }
  
  .moduleSaveBtn {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .ModuleUpdateViewForm_Body {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; /* Allow content to wrap in smaller screens */
  }
  
  .ModuleUpdateViewForm_Body > div {
    width: 48%; /* 2 sections side-by-side */
  }
  
  .userlabels {
    font-size: 14px;
    color: #333;
    width: 100%; /* Full width for inputs */
  }
  
  .userlabels input {
    width: 100%;
    padding: 8px;
    margin-top: 4px;
    border-radius: 5px;
    border: 1px solid #ccc;
  }
  
  .FormViewPage {
    width: 48%;
  }
  
  .FormViewPage nav {
    display: flex;
    margin-bottom: 20px;
  }
  
  .FormViewPage a {
    margin-right: 10px;
    text-decoration: none;
    color: #333;
  }
  
  .FormViewPage a:hover {
    text-decoration: underline;
  }
  
  .FormViewPage h3 {
    font-size: 18px;
    margin-bottom: 10px;
  }
  
  .EditUserModuleRBA {
    margin-top: 20px;
  }
  
  .AddBtn {
    padding: 8px 16px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .ReactQuill {
    margin-top: 20px;
  }
  
  textarea, .record_note {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-top: 10px;
  }
  
  /* Media Query for smaller devices */
  @media (max-width: 768px) {
    .ModuleUpdateViewForm {
      padding: 10px;
    }
  
    .ModuleUpdateViewForm_Header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .RecordID h1 {
      font-size: 20px;
    }
  
    .moduleSaveBtn {
      margin-top: 10px;
      width: 100%;
    }
  
    .ModuleUpdateViewForm_Body {
      flex-direction: column;
    }
  
    .ModuleUpdateViewForm_Body > div {
      width: 100%; /* Stack Name/Email and Pages vertically */
      margin-bottom: 20px;
    }
  
    .FormViewPage {
      width: 100%;
    }
  
    .userlabels {
      width: 100%;
    }
  
    .FormViewPage nav {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .FormViewPage a {
      margin-right: 0;
      margin-bottom: 10px;
    }
  
    .EditUserModuleRBA {
      width: 100%;
    }
  
    .ReactQuill {
      height: 120px;
    }
  }
  
  /* Media Query for very small devices (e.g., phones in portrait mode) */
  @media (max-width: 480px) {
    .ModuleUpdateViewForm_Header {
      padding: 0 10px;
    }
  
    .userlabels input {
      font-size: 14px;
      padding: 6px;
    }
  
    .ReactQuill {
      height: 100px;
    }
  }
   .newusermodalLeft,
  .newusermodalRight {
    width: 48%;
    display: flex;
    flex-direction: column;
  }
  