.recordAuthor {
  display: flex;
  flex-direction: row;
  /* gap: 16px;
  padding: 20px; */
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  /* max-width: 500px; */
  margin: 0 auto;
}

.InternalRefInput {
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 100%;
  background-color: #aea4a4; /* Light gray background to show it's not editable */
  color: #151414; /* Slightly dark text for readability */
  cursor: not-allowed; /* Cursor indicates it's read-only */
  opacity: 0.7; /* Slight opacity to show it's read-only */
}
.ProductDetailsGroup {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.productlabels {
  font-weight: bold;
  margin-bottom: 8px;
}

.InternalRefInput,
input[type="text"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

@media (max-width: 600px) {
  .ProductDetailsGroup {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
.PricingGroup {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.productlabels {
  font-weight: bold;
  margin-bottom: 8px;
}

input[type="number"] {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .PricingGroup {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
.CategoryGroup {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin: 20px 0;
}

.form-group {
  display: flex;
  flex-direction: column;
}

.productlabels {
  font-weight: bold;
  margin-bottom: 8px;
}

select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  background-color: #fff;
  appearance: none;
  box-sizing: border-box;
}

@media (max-width: 600px) {
  .CategoryGroup {
    grid-template-columns: 1fr;
    gap: 15px;
  }
}
.MediaGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin: 20px 0;
}

.form-group {
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
}

.productlabels {
  font-weight: bold;
  margin-bottom: 8px;
}

.image-upload-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
}

input[type="file"] {
  margin-top: 10px;
}

.ProductPreviewImg {
  max-width: 100%;
  max-height: 200px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

textarea {
  width: 100%;
  height: 120px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
}

@media (max-width: 600px) {
  .MediaGroup {
    flex-direction: column;
  }

  .ProductPreviewImg {
    max-width: 100%;
    height: auto;
  }
}
.form-container {
  width: 100%;
  max-width: 1200px;
  margin: auto;
  padding: 20px;
}

.form-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.form-grid {
  display: grid;
  gap: 20px;
}

.form-row {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}

label {
  flex: 1;
  min-width: 300px;
}

input, select, textarea {
  width: 100%;
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

@media (max-width: 768px) {
  .form-row {
    flex-direction: column;
  }

  label {
    min-width: 100%;
  }

  .recordAuthor{
    flex-direction: column;
  }
}
