.ModuleCreateView {
  display: flex;
  max-width: 95%;
  flex-grow: 1;
  /* justify-content: center;
  align-items: center; */
  flex-direction: column;
  padding: 5px;
  /* background-color: #f8f9fa; */
  /* border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */

  /* max-width: 1000px; */
  /* margin: 40px auto; */
  /* font-family: Arial, sans-serif; */
}

.ModuleCreateViewForm {
  display: flex;
  flex-direction: column;
}

.ModuleCreateViewForm_Header {
  display: flex;
  max-width: 100%;
  flex-grow: 1;
  justify-content: space-between;
  border-bottom: 2px solid #ddd;
}

.FormTitle {
  color: orange;
}

.ModuleCreateViewForm_Bottom {
  display: flex;
  max-width: 100%;
  flex-direction: column;
  border-top: 2px solid #ddd;
}

.record_note {
  max-width: 100%; /* Full width of the container */
  padding: 10px; /* Inner padding for better text spacing */
  border: 1px solid #ccc; /* Light gray border */
  border-radius: 5px; /* Rounded corners */
  font-size: 16px; /* Font size for readability */
  line-height: 1.5; /* Line height for better readability */
  resize: vertical; /* Allow vertical resizing only */
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
}
