/* Reset and base styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.signIn {
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
  padding: 1rem;
  overflow: auto; /* Allow vertical scroll if needed */
}

.signIn_Form_div {
  background: white;
  padding: 2rem;
  border-radius: 16px;
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.12);
  width: 100%;
  max-width: 420px;
  margin: 1rem auto;
  overflow-y: auto; /* Allow scroll if content is too tall */
  max-height: 95vh; /* Prevent form from being taller than viewport */
}

/* Logo and company section */
.signin_company {
  text-align: center;
  margin-bottom: 1.5rem;
}

.logo-container {
  width: 100px; /* Reduced from 120px */
  height: 100px; /* Reduced from 120px */
  margin: 0 auto 1rem;
  padding: 8px;
  border-radius: 50%;
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.logo {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: contain;
  max-width: 100%; /* Ensure logo doesn't overflow */
}

/* Form elements */
.form-container {
  margin-top: 1.5rem;
}

.form-group {
  margin-bottom: 1rem;
  width: 100%;
}

.signin-input,
.ant-input-affix-wrapper {
  width: 100%;
  height: 40px;
}

/* Ensure inputs don't overflow */
.ant-input {
  max-width: 100%;
}

.ant-input-password {
  width: 100%;
}

/* Button and action area */
.signin-actions {
  margin-top: 1.5rem;
  width: 100%;
}

.login-button {
  width: 100%;
  height: 40px;
}

/* Footer */
.signin-footer {
  margin-top: 1.5rem;
  padding-bottom: 0.5rem;
  width: 100%;
}

/* Media Queries */
@media screen and (max-width: 480px) {
  .signIn_Form_div {
    margin: 0.5rem;
    padding: 1.25rem;
    max-height: 98vh;
  }

  .logo-container {
    width: 80px;
    height: 80px;
  }

  .login_company_name {
    font-size: 1.25rem;
  }
}

@media screen and (max-height: 600px) {
  .signIn_Form_div {
    padding: 1rem;
    margin: 0.5rem;
  }

  .logo-container {
    width: 60px;
    height: 60px;
    margin-bottom: 0.5rem;
  }

  .form-group {
    margin-bottom: 0.75rem;
  }

  .signin-footer {
    margin-top: 1rem;
  }
}

/* Ensure Ant Design components don't overflow */
.ant-spin-container,
.ant-spin-nested-loading {
  width: 100%;
  max-width: 100%;
}

.ant-input-affix-wrapper {
  max-width: 100%;
}

/* Toast container fix */
/* .Toastify__toast-container {
  width: auto;
  max-width: 90vw;
  z-index: 9999;
} */
