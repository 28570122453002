.Profile_Edit {
  max-width: 800px;
  margin: 2rem auto;
  padding: 2rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.Profile_Edit h3 {
  color: #2c3e50;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 1.8rem;
}

.ChangeAvatar, .ChangePassword {
  background: #f8f9fa;
  padding: 2rem;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.ChangeAvatar form, .ChangePassword form {
  gap: 1.5rem;
  align-items: center;
}

.ChangePassword h2 {
  color: #2c3e50;
  font-size: 1.4rem;
  margin-bottom: 1.5rem;
}

/* File input styling */
/* input[type="file"] {
  border: 1px dashed #cbd5e0;
  padding: 1rem;
  border-radius: 4px;
  width: 100%;
  margin-bottom: 1rem;
} */

/* Image preview */
.ChangeAvatar img {
  border-radius: 50%;
  border: 3px solid #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin: 1rem 0;
}

/* Password inputs */
.ant-input-password {
  margin-bottom: 1rem !important;
}

/* Buttons */
/* button {
  background-color: #3498db;
  color: white;
  padding: 0.8rem 1.5rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.2s;
  width: 100%;
  max-width: 200px;
  align-self: center;
} */

/* button:hover {
  background-color: #2980b9;
} */

/* Labels */
/* label {
  color: #4a5568;
  font-weight: 500;
  margin-bottom: 0.5rem;
} */

/* Responsive design */
@media (max-width: 768px) {
  .Profile_Edit {
    margin: 1rem;
    padding: 1rem;
  }
  
  .ChangeAvatar, .ChangePassword {
    padding: 1rem;
  }
}

.telegram-container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  align-items: center;
}

.qr-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  padding: 1rem;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.qr-section h3 {
  margin: 0;
}

.qr-section a {
  color: #1890ff;
  text-decoration: none;
}

.qr-section a:hover {
  text-decoration: underline;
}
