.forgetMain {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f9f9f9;
  font-family: "Arial", sans-serif;
}

.forgetContainer {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
}

/* h1 {
  margin-bottom: 1rem;
  font-size: 24px;
  text-align: center;
} */

/* label {
  display: block;
  margin-bottom: 0.5rem;
} */

/* input[type="email"] {
  width: 100%;
  padding: 10px;
  margin-bottom: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
} */

/* button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;
} */

/* button:hover {
  background-color: #0056b3;
} */

/* .error {
  color: red;
  text-align: center;
  margin-top: 1rem;
} */

/* .success {
  color: green;
  text-align: center;
  margin-top: 1rem;
} */
